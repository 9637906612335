import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";

const Container = styled(Flex).attrs({
  maxWidth: { _: "220px", programsLg: "116px" },
})`
  position: ${({ fixedToViewport }) => (fixedToViewport ? "fixed" : "sticky")};
  margin-top: ${({ fixedToViewport }) =>
    fixedToViewport ? "60px" : "calc(-100vh + 60px)"};
  top: 0;
  left: 0;
  padding-left: ${({ fixedToViewport }) => (fixedToViewport ? null : "2px")};
  z-index: 2;
  align-content: center;
  margin-left: 16px;
  align-self: flex-start;
  height: calc(100vh - 60px);
`;

const NavLinks = styled(Div)`
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 8px;
  align-content: center;
`;

const NavLink = styled(Text)`
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  transition: opacity ease-in-out 200ms, padding-left ease-in-out 200ms;
  padding-left: 0;
  left: 16px;
  cursor: pointer;
  &:hover {
    opacity: ${({ active }) => (active ? null : 0.8)};
    padding-left: 4px;
  }
  &::before {
    content: "-";
    position: absolute;
    left: ${({ active }) => (active ? "-16px" : "-24px")};
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity ease-in-out 200ms, left ease-in-out 200ms;
  }
`;

const SideNav = ({ white = false, fixedToViewport = true }) => {
  const { slug } = useParams();
  const { topmostBlockId } = useSelector(state => state.programView);
  const [programsV2Data, loading, error] = useGetProgramsV2({
    variables: { slug },
    options: { nextFetchPolicy: "cache-first" },
  });

  const blockLinks = useMemo(() => {
    const links = [];
    if (!programsV2Data) {
      return links;
    }
    links.push({ title: "Overview", scrollId: "program-overview-container" });
    programsV2Data.blocks.forEach(block => {
      const linkItem = {
        title: block.superscript,
        scrollId: `program-block-el-${block.sortIndex}`,
      };
      links.push(linkItem);
    });
    if (programsV2Data.relatedClasses) {
      links.push({
        title: "Related classes",
        scrollId: "related-classes-container",
      });
    }
    return links;
  }, [programsV2Data]);

  const handleLinkClick = useCallback(
    scrollId => () => {
      const el = document.getElementById(scrollId);
      if (!el) {
        return;
      }
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    []
  );

  if (loading || error) {
    return <></>;
  }
  return (
    <Container fixedToViewport={fixedToViewport}>
      <NavLinks>
        {blockLinks.map(stageLink => (
          <NavLink
            active={topmostBlockId === stageLink.scrollId}
            onClick={handleLinkClick(stageLink.scrollId)}
            color={white ? "white" : undefined}
            key={stageLink.title}
          >
            {stageLink.title}
          </NavLink>
        ))}
      </NavLinks>
    </Container>
  );
};

SideNav.propTypes = {
  white: PropTypes.bool,
  fixedToViewport: PropTypes.bool,
};

export default SideNav;
